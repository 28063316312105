<template>
  <div class="relative w-full rounded-md shadow-sm">
    <div
      class="pointer-events-none absolute inset-y-0 right-4 sm:right-2 lg:right-4 flex items-center"
    >
      <MagnifyingGlassIcon class="h-4 w-4 text-green-500" />
    </div>
    <input
      type="text"
      class="block w-full rounded-md h-8 pl-4 sm:pl-2 lg:pl-4 border-gray-300 focus:border-prueba focus:ring-prueba sm:text-sm sm:h-10"
      placeholder="¿Qué estás buscando?"
      v-model="busqueda"
      @keypress.enter="buscar"
    />
  </div>
</template>
<script setup lang="ts">
  import { ref } from "@vue/runtime-core";
  import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";

  const busqueda = ref("");

  function buscar() {
    window.location.href = `/busqueda/${busqueda.value}`;
  }
</script>
